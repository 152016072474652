@import "../../../assets/scss/variables.scss";
.header-main {
	padding: 14px 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
	.header-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
        .header-logo{
            img{
                width: 120px;
                @media(max-width: 767px){
                    width: 110px;;
                }
            }
        }
	}
	.header-menu {
		a {
			font-size: 13px;
			color: $white;
			text-decoration: none;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-left: 65px;
            @media(min-width: 768px) and (max-width: 1024px){
                margin-left: 30px;
            }            
            &:hover{
                color: $secondary-color;
            }
		}
        .demo-item{
            border: 2px solid $secondary-color;
            padding: 10px 22px;
            &:hover{
                color: $white;
                background-color: $secondary-color;
            }
        }
	}
}
.hamburger {
    display: none;
    @media(max-width: 767px){
        display: block;
    }
    .hline{
        width: 30px;
        height: 2px;
        background-color: $white;
        margin: 6px 0;
        display: block;
        transition: 0.3s;
    }
}
@media(max-width: 767px) {
    .header-logo.show {
        position: fixed;
        z-index: 9;
        top: 15px;
        left: 15px;
    }
    .header-menu.dn-mob {
        display: none;
    }
    .hamburger.show span:nth-child(2) {
        display: none;
    }
    
    .hamburger.show span:nth-child(1) {
        transform: rotate(45deg);
        top: 7px;
        position: relative;
    }
    
    .hamburger.show span:nth-child(3) {
        transform: rotate(136deg);
    }
    .hamburger.show {
        z-index: 9;
        position: fixed;
        right: 15px;
        top: 27px;
    }
    .header-main {
        padding: 15px 0;
        .header-menu {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            padding: 100px 0;
            top: 0;
            background: #000;
            text-align: center;
            z-index: 2;
            a {
                display: block;
                margin: 0;
                padding: 25px 0px;
                font-size: 24px;
            }
            .demo-item {
                border: 0;
                padding: 12px 0px;
            }
        }
    }
    
}