/*
*
Handyhost
*                                                           
*/ 

// -------- Colors -----------
// @primary-color: @blue-6;
// @info-color: @blue-6;
// @success-color: @green-6;
// @processing-color: @blue-6;
// @error-color: @red-6;
// @highlight-color: @red-6;
// @warning-color: @gold-6;
// @normal-color: #d9d9d9;

$white: #ffffff;
$black: #000000;
$light: #9FA3A7;

$primary-color: #000000; // primary color for all components
$secondary-color: #8E43CC; 
$tersary-color: #15181D;
$success-color: #1db100;
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color

$grey-btn-color: #737373;
$input-light-grey-border: #B8B8B8;
$input-grey-bg: #eeeeee;



$background-primary: #F5F5F5;
$background-secondary: #E9E9EB;
$dark-border:#3C3C3C;
$input-label-color:#929292;

$font-family: 'ProximaNova-Regular';
$font-family-sb: 'ProximaNova-Semibold';
$font-family-eb: 'ProximaNova-Extrabld';
$font-family-light: 'ProximaNova-Light';
$font-family-bold: 'ProximaNova-Bold';

$font-size-base: 14px;
$font-size-xlg: $font-size-base + 4px; //18px
$font-size-lg: $font-size-base + 2px; //16px
$font-size-sm: $font-size-base - 2px; //12px

$text-color-primary: #000000; // major text color
$text-color-secondary: #A1C152; // secondary text color
$text-red: #d90a00; // Red text color
$text-white: #ffffff; // white text color
$link-color: $primary-color; // link color
$disabled-color: rgba(0, 0, 0, 0.1); // disable state color
$border-radius-base: 4px; // major border radius
$border-color-base: $background-secondary; // major border color
$box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

